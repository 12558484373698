export const SET_FEATURES_LIST = 'setFeaturesList'
export const SET_FEATURES_CHOSEN_BY_USER = 'featuresChosenByUser'
export const SET_DONE_UPLOAD_FILE = 'setDoneUploadFile'
export const SET_CHECKED_SELECT_ALL = 'setCheckedSelectAll'
export const SET_FEATURE_DESCRIPTION = 'setFeatureDescription'
export const SET_TITLE_FEATURE_DESCRIPTION = 'setTitleFeatureDescription'
export const SET_EXISTING_FILES_LIST = 'setExistingFilesList'
export const SET_FEATURES_LIST_OUTPUT = 'setFeatureListOutput'
export const SET_DATA_HIST = 'setDataHist'
export const SET_MISSING_NAMES_BY_TYPE = 'setMissingNamesByType'
export const SET_NAMES_BY_PRODUCT_TYPE = 'setNamesByProductType'
export const SET_NUMERIC_FEATURE_TITLE_X_Y = 'setNumericFeatureTitleXY'
export const SET_CURRENT_PAGE = 'setCurrentPage'
export const SET_STATISTIC_FEATURE_HIST = 'setStatisticFeatureHist'
export const RESET_FEATURE_LIST = 'resetFeatureListOutput'

